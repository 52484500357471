<!-- Лівий блок картки тікету -->

<template>
    
    <!-- <div class="chat-leftsidebar bg-info">
        dsd
    </div> -->
    
    <!-- лефтбар -->
    <div class="left-block">
        <div class="card card-info" bis_skin_checked="1">
            <div class="card-body text-center" bis_skin_checked="1">
                <div class="d-flex align-items-center" bis_skin_checked="1">
                    <div class="flex-grow-1 ms-3" bis_skin_checked="1">
                        <h3 class="card-title">#3374 - Модуль зп</h3>
                        <p class="card-text"><span class="fw-medium">Дата створення : 2025-01-08 15:55:01</span></p>
                    </div>
                </div>
            </div>
            <div class="card-footer copy" bis_skin_checked="1">
                <div class="text-center" bis_skin_checked="1">
                    <a class="link-light">Копіювати <i class="bx bx-copy align-middle lh-1"></i></a>
                </div>
            </div>
        </div>

        <div class="card-body" bis_skin_checked="1">
    <!----><!---->
    <div class="card-header align-items-center d-flex" bis_skin_checked="1">
        <h4 class="card-title mb-0 flex-grow-1">Про тикет</h4>
        <div class="flex-shrink-0" bis_skin_checked="1">
            <div class="dropdown card-header-dropdown" bis_skin_checked="1">
                <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i> Дія</span>
                </a>
                <div class="dropdown-menu dropdown-menu-end" bis_skin_checked="1"><button class="dropdown-item">Змінити пріоритет</button><button class="dropdown-item">Змінити середовище проблеми</button></div>
            </div>
        </div>
    </div>
    <div class="card-body" bis_skin_checked="1">
        <div class="table-responsive table-card" bis_skin_checked="1">
            <table class="table table-borderless align-middle mb-0">
                <tbody>
                    <tr>
                        <td class="fw-medium">Тикет</td>
                        <td>#3374</td>
                    </tr>
                    <tr>
                        <td class="fw-medium">Тип тикету</td>
                        <td class="hstack text-wrap gap-1">Проблема</td>
                    </tr>
                    <tr>
                        <td class="fw-medium">Середовище проблеми</td>
                        <td class="hstack text-wrap gap-1">Нова адмін-панель</td>
                    </tr>
                    <!---->
                    <tr>
                        <td class="fw-medium">Статус:</td>
                        <td><span class="badge badge badge-soft-secondary">В роботі</span></td>
                    </tr>
                    <tr>
                        <td class="fw-medium">Пріоритет</td>
                        <td><span class="badge badge bg-warning">Середній</span></td>
                    </tr>
                    <tr>
                        <td class="fw-medium">Додав</td>
                        <td>Микола</td>
                    </tr>
                    <tr>
                        <td class="fw-medium">Дата створення</td>
                        <td>2025-01-08 15:55:01</td>
                    </tr>
                    <tr class="border-top">
                        <td class="fw-medium">Дата та час виконання</td>
                        <td>2025-01-09 13:51:32</td>
                    </tr>
                    <tr class="border-top">
                        <td class="fw-medium">Мітки</td>
                        <td><button type="button" class="btn btn-soft-info waves-effect waves-light">додати</button></td>
                    </tr>
                    <div bis_skin_checked="1"></div>
                </tbody>
            </table>
        </div>
    </div>
</div>

    </div>

</template>

<script>
import { nameWorker, tgAccWorker } from "@/usabilityScripts/getNameWorker";
import { Tickets } from "@/API";
import { storeS } from "@/store";
import {
  mutateTypeTicket,
  mutateStatusTicket,
  mutateDecidedStatusTicket,
  mutatePriorityTicket,
  mutateProblemEnv,
  nameTag,
} from "@/usabilityScripts/globalMutate.js";
import { sendTelegramChangePTicket } from '@/usabilityScripts/logsTelegram.js'
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new Tickets();

export default {
    props: ["obj", "viewRepBox"],
    data() {
        return {
            form: {}, 
            newForm: {
                priority: "",
            },
        };
    },
    watch: {
        obj: {
            handler(newObj) {
                this.form = { ...newObj }; // Оновлюємо локальну копію при зміні пропса
            },
            immediate: true, // Виконуємо спостереження відразу після підключення
            deep: true, // Відстежуємо вкладені зміни
        },
    },
    created(){
        this.newForm.priority = this.form.priority;
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        nameWorker(item) {
            return nameWorker(item);
        },
        tgWorker(item) {
            // отримання нікнейму співробітника
            return tgAccWorker(item);
        },
        mutateticket(e) {
            return mutateTypeTicket(e);
        },
        mutatestatus(e, form) {
            return mutateStatusTicket(e, this.nameWorker(form.assignedWorkerId));
        },
        mutatepriority(e) {
            return mutatePriorityTicket(e);
        },
        mutateDecidedStatus(e) {
            return mutateDecidedStatusTicket(e);
        },
        mutateProblemEnv(e) {
            return mutateProblemEnv(e);
        },
        nameTag(e) {
            return nameTag(e);
        },
    },
    computed: {
        workers() {
            return storeS.workers;
        },
        user() {
            return storeS.userbase;
        },
        perms() {
            return storeS.perms;
        },
    },
};
</script>

<style scoped>

</style>