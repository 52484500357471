<!-- Новий список тікетів -->

<template>

    <!-- 
        Компонент таблиці 
        @search - дія пошуку
        @addCategory - дія додавання табу
        @removeCategory - дія видалення табу
        @create - дія створення тікету
        @open - дія відкриття тікету
        @changeFilter - дія зміни фільтру (табу)
        @clearFilter - дія очистки фільтру
        @remove - дія видалення тікету
        @getdata - дія отримання даних по тікетам
        @changelimit - зміна кількості відображення тікетів
        @changeTab - зміна табу
        :dataParams - пропс базових параметрів
        :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
        :columns - прпос передачі колонок
        :rows - пропс передачі самих тікетів
        :pages - пропс передачі номеру сторінки
        :showpag - пропс показування пагінації
        :tabs - пропс, що передає інформацію про таби
        :addtabs - дозвіл на додавання своїх табів
        :createdform - пропс на додавання тікетів
    -->
    <tablecustom 
        v-if="!unclosedTicketsShow && !unclosedTicketsByWorkersShow && !unclosedTicketsByEnvironmentsShow"
        @search="searchB"
        @create="createmodalNewForm = true"
        @open="open" 
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeWithClearFilter="changeWithClearFilter"
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="showpag"
        :createdform="this.perms[301] || this.perms[2000]"
        @sortColumn="sortColumn" 
        :addtabs="true"
        @addCategory="showFilterBox = true"
        :tabs="tableTabs"
        :verTabs="'new'"
        @editTab="editTab"
        @removeTab="removeTab"
        @changeTabNew="changeTabNew"
    />

    <!--
        Модалка створення табів
        @close - дія закриття табів
        @changeTabs - дія зміни табу
        :othertabs - пропс табів
    -->
    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @saveTab="saveTab"
        :editPermFilter="editPermFilter"
        :objEditTab="objEditTab"
    />

    <!-- 
        Модальне вікно підтвердження видалення повідомлення
        - йде перевірка showDialogRemove
        - @close - подія закриття модалки
        - @close - подія видалення повідомлення
    -->
    <removeModal
        v-if="showDialogRemove" 
        @close="showDialogRemove = false" 
        @remove="remove"
    >
    </removeModal>

</template>

<script>
import PageHeader from "@/components/page-header"; // Для заголовку сторінки
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createTicketNewForm from '@/views/newtickets/createTicketNewForm' // модальне вікно створення тікету
import filterTabs from '@/views/newTicketsSection/filterTabs'
import removeModal from '@/components/modal/modalremove.vue' // Діалогове вікно підтвердження видалення
// import viewBoxTicket from './view/index.vue' // картки перегляду тікету
import { Tickets } from '@/API.js';
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
    } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Tickets();

export default {
    components: {
        PageHeader,
        tablecustom,
        createTicketNewForm,
        filterTabs,
        removeModal,
    },
    data() {
        return {
            title: this.$t('tickets'), // назва сторінки
            infoblockShow: false,
            unclosedTicketsShow: false, // відображення тікетів по клієнтам групами
            unclosedTicketsByWorkersShow: false, // відображення тікетів по розробникам групами
            unclosedTicketsByEnvironmentsShow: false, // відображення тікетів по проблемам групами
            createmodalNewForm: false, // для модалки створення тікету
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            ifNextopen: "", // Прапорець статусу відображення кнопки "<"
            ifPrevopen: "", // Прапорець статусу відображення кнопки ">"
            showModal: false, // відображення вікна тікету
            dataParams: {  // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "tickets"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '25',
                search: '',
                priority: '',
                status: '',
                decidedStatus: '',
                type: '',
                workerId: '',
                inJobWorkerId: '',
                problemEnvironment: '',
                pr1: '',
                pr2: '',
                tags: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("Категорія тікету"),
                    text: "type",
                    align: "left",
                    mutate: (item) => "Технічна проблема",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("Тип тікету"),
                    text: "type",
                    align: "left",
                    mutate: (item) => "Проблема",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("problem_area"),
                    text: "problemEnvironment",
                    align: "left",
                    mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTicketName(item),
                    width: '10'
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                    sort: 'create_datetime',
                    sortType: 'asc',
                    width: '10'
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("clients"),
                    text: "userPhones",
                    align: "right",
                    mutate: (item) => item ? this.mutateNumber(item) : '-',
                    status: false,
                    width: '10'
                },
                {
                    name: this.$t("labels"),
                    text: "tags",
                    align: "right",
                    status: false,
                    mutate: (item) => item ? this.nameTag(item) : '-',
                    width: '10'
                },
            ],
            rows: [],
            hoveredLabel: 'Hover over a section', // необхідно для доната
            hoveredValue: null,
            activeSection: null,
            sections: [],
            // для налаштування табу
            showFilterBox: false, // відображення вікна створення/редагування табу
            objEditTab: {}, // об'єкт табу
            editPermFilter: false, // редагування табу
            tableTabs: [], // таби
            showDialogRemove: false, // прапорець відображення вікна видалення табу
        }
    },
    created() {
        if(this.checks['004']){
            // приховування тікетів, що не пов'язані зі мною
            // this.getdataForCheck();
        } else {
            this.objParams.pagelimit = storeS.pagelimit ? storeS.pagelimit : this.objParams.pagelimit
            this.getdata();
        }
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про тікети з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            // поки без поняття
            this.showpag = true

            this.dataParams.status = true
            if(this.objParams['tags[]']) {
                delete this.objParams['tags[]']
                this.objParams.tags = ''
            }

            this.objParams.page = page != undefined ? page : '1';

            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items;  

                    this.information[0].value = result.data.total
                    this.information[1].value = result.data.countNew
                    this.information[2].value = result.data.countInJob
                    this.information[3].value = result.data.countDecided

                    this.sections = this.generateSections({
                        countDecided: result.data.countDecided,
                        countInJob: result.data.countInJob,
                        countNew: result.data.countNew,
                        total: result.data.total,
                    });

                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + ` # 300`);
            })
        },
        getdataForCheck(){
            // отримання інформації, якщо у людини було налаштування 004
            // 004 - ховати тікети не пов'язані з користувачем

            this.objParams.pagelimit = '100'

            this.objParams.inJobWorkerId = this.user.userid

            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    let objPages1 = result.data
                    this.rows = result.data.items;  
                    this.objParams.inJobWorkerId = ''
                    this.objParams.workerId = this.user.userid

                    apiServe.getTickets(this.objParams).then(res => {
                        for(var item in objPages1) {
                            if(res.data[item]) {
                                if(typeof objPages1[item] == 'object') {
                                objPages1[item] = objPages1[item].concat(res.data[item])
                                } else {
                                objPages1[item] += res.data[item]
                                }
                            }
                        }

                        this.objPages = objPages1
                        this.showpag = false // вирубаємо пагінацію
                        this.objParams.workerId = ""
                        this.objParams.inJobWorkerId = this.user.userid
                        // this.dataParams.status = false // вирубаємо фільтри
                        this.rows = this.rows.concat(res.data.items)
                    })
                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            })

        },
        open: function(e){
             /**
                Функція для відкриття модального вікна з інформацією про тікет за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор тікету або об'єкт з інформацією про тікет.

                Викликає API для отримання інформації про тікет.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка тікету, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка тікету, після поточної відкритої картки
            */

            // Визначення ідентифікатора тікету.
            var id = e.ticketId ? e.ticketId : e;

            // Перевірка, чи тікет є першим у списку для відкриття вікна "Попередній тікет".
            this.ifPrevopen = id == this.rows[0].ticketId ? false : true;

            // Перевірка, чи тікет є останнім у списку для відкриття вікна "Наступний тікет".
            this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
            
            apiServe.getTicket(id).then(result => {
                if(result.status == 'done'){

                    if(!Array.isArray(result.data.userPhones)) {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка відкриття тікету. Номери string'; 
                        var statusRequest = "error";
                        var api = 'getTicket';
                        var fileRequest = 'src/views/newtickets/all.vue';
                        var params = id;
                        var response = result.data.userPhones

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }

                    this.form = result.data;
                    this.$emit('addTicketList', this.form)
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка відкриття тікету'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/views/newtickets/all.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal в false, та викликає функцію getdata для оновлення даних таблиці.
            */
            this.showModal = false
            this.getdata(this.objParams.page);
        },
        closeCreate(){
            // закрити модалку створення
            this.permToEdit = false;
            this.createmodal = false;
            this.createmodalNewForm = false;
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            console.log(...arguments)
            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.decidedStatus = '',
                this.objParams.type = '',
                this.objParams.problemEnvironment = '',
                this.objParams.workerId = '',
                this.objParams.inJobWorkerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.objParams.tags = '';
                this.getdata();
            } else if(name == 'problemEnvironments'){
                this.objParams.problemEnvironments = JSON.stringify(value)
                this.getdata();
            } else if(name == 'problemEnvironment') {
                this.objParams.problemEnvironments = ''
                this.getdata();
            }else if(!name.type && value != undefined){
                if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
                } else if(name == 'tags'){
                this.objParams[name] = value[0] == "" ? '' : JSON.stringify(value); 
                } else {
                this.objParams[name] = value;
                }
                this.getdata();
            } else {
                this.objParams[name] = value;
            }
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за ...
            */

            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            // змінити ліміт і оновити дані
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeTabNew(item){
            console.log("changeTabNew", item);

            // Зберігаємо значення ключів, які не потрібно очищати
            const retainedKeys = ['of', 'ot', 'page', 'pagelimit', 'problemEnvironment'];
            const retainedValues = {};

            // Зберігаємо значення ключів, які залишаємо
            for (const key of retainedKeys) {
                if (this.objParams.hasOwnProperty(key)) {
                    retainedValues[key] = this.objParams[key];
                }
            }

            // Очищення всіх фільтрів
            for (const key in this.objParams) {
                if (this.objParams.hasOwnProperty(key)) {
                    this.objParams[key] = '';
                }
            }

            // Відновлення значень для ключів, які залишаємо
            for (const key in retainedValues) {
                if (retainedValues.hasOwnProperty(key)) {
                    this.objParams[key] = retainedValues[key];
                }
            }

            // Оновлення objParams відповідно до нових фільтрів
            const newFilters = item.filters;
            for (const key in newFilters) {
                if (newFilters.hasOwnProperty(key) && this.objParams.hasOwnProperty(key)) {
                    this.objParams[key] = newFilters[key];
                }
            }

            // Отримуємо дані з новими параметрами фільтру
            this.getdata()            
        },
        saveTab(item){
            // Додаємо або змінюємо таб

            if(this.editPermFilter == false) {
                this.tableTabs.push(item)
                this.$toast.success(this.$t('Added'))
            } else {
                // Знаходимо індекс табу для редагування
                const tabIndex = this.tableTabs.findIndex(
                    (tab) => tab.settingTab.nameTab === this.objEditTab.settingTab.nameTab
                );

                if (tabIndex !== -1) {
                    // Оновлюємо таб
                    this.tableTabs[tabIndex] = item;
                }

                this.$toast.success(this.$t('alertChanged'))
            }
        
            localStorage.setItem("TicketsNewSection", JSON.stringify(this.tableTabs));
            localStorage.setItem("resultTicketsNewSection", this.tableTabs.length)

            this.closeModalFilterTab()
        },
        editTab(item) {
            // Редагування табу

            this.objEditTab = item;
            this.editPermFilter = true;
            this.showFilterBox = true
        },
        removeTab(item){
            // Видалення табу (виклик вікна підтвердження)

            this.removeTabObj = item;
            this.showDialogRemove = true
        },
        remove(){
            // Видаляємо таб

            // Знаходимо індекс табу для редагування
            const tabIndex = this.tableTabs.findIndex(
                (tab) => tab.settingTab.nameTab === this.removeTabObj.settingTab.nameTab
            );
            this.tableTabs.splice(tabIndex, 1);

            localStorage.setItem("TicketsNewSection", JSON.stringify(this.tableTabs));
            localStorage.setItem("resultTicketsNewSection", this.tableTabs.length)
            this.$toast.success(this.$t('Removed'))
            this.closeModalFilterTab()
        },
        closeModalFilterTab(){
            // Закрити вікно табу
            this.editPermFilter = false;
            this.objEditTab = false;
            this.showFilterBox = false;
            this.removeTabObj = {};
            this.showDialogRemove = false;
        },
        toggleButton(button) {
            // Для зміни додаткової загальної інформації 

            // Якщо кнопку натискають вдруге — вимикаємо її
            if (this[button]) {
                this[button] = false;
            } else {
                // Скидаємо всі інші кнопки
                this.unclosedTicketsShow = false;
                this.unclosedTicketsByWorkersShow = false;
                this.unclosedTicketsByEnvironmentsShow = false;

                // Вмикаємо лише ту кнопку, яку натиснули
                this[button] = true;
            }
        },
        mutateTicketName(e) {
            // скорочує назву тікету

            let name = ''
            if(e.length > 50) {
                name = e.slice(0, 46) + '...'
            } else {
                name = e
            }
            return name
        },
        nameWorker(item){
            // мутейт співробітника
            return nameWorker(item)
        },
        mutateNumber(value){
            // мутейт телефонів клієнта
            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
                }
            }
            return r
        },  
        mutateticket(e){
            // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
            // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateProblemEnv(e){
            // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
        nameTag(value){
            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
                }
            }
            return r
        },
    },
    mounted(){
        // якщо існують таби в сторі, то дістаємо їх 
        this.tableTabs = localStorage.getItem("TicketsNewSection") ? JSON.parse(localStorage.getItem("TicketsNewSection")) : [];
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        workers() {
            /*
                Повертає дані співробітників з глобального стору.
            */
            return storeS.workers
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        statusTheme(){
            // Отримання статусу теми
            return storeS.nightTheme
        },
        // highlightedSections() {
        //     // Необхідно для донату по тікетам
        //     return this.sections.map((section) => ({
        //         ...section,
        //         color:
        //         section === this.activeSection
        //             ? this.darkenColor(section.color, 0.2)
        //             : section.color,
        //     }));
        // },
    },
}
</script>