<!-- Картка перегляду тікету -->
<template>
    <modal :key="componentKey" :title="$t('ticket')" @close="close">

        <template v-slot:body>

            <!-- Вкладки -->
            <ul class="nav nav-tabs position-relative" role="tablist">
                <li class="nav-item" v-for="(ticket, index) in ticketsWithAddTab" :key="index">
                    <button
                        class="nav-link position-relative tabsText"
                        :class="{
                            active: activeTab === index,
                            'first-tab': index === 0,
                            'add-tab': !ticket.ticketId,
                        }"
                        @click="setActiveTab(index)"
                    >
                        <template v-if="ticket.ticketId">{{ $t('ticket') }} #{{ ticket.ticketId }}</template>
                        <template v-else>+ Відкрити тікет</template>
                    </button>
                </li>
                <!-- Лінії під іншими табами -->
                <div class="tab-lines"></div>
            </ul>

            <div style="height: calc(100% - 70px);">

                <!-- Контент вкладок -->
                <div class="tab-content" style="height:100%;">
                    <div
                        class="tab-pane fade"
                        :class="{ show: activeTab === index, active: activeTab === index }"
                        v-for="(ticket, index) in ticketsWithAddTab"
                        style="height:100%;"
                        :key="index"
                    >
                        <template v-if="ticket.ticketId">
                            <!-- <b-row>

                                <b-col lg="3"> -->
                                
                                    <!-- Вміст тікету -->
                                    <formLeft 
                                        :obj="tickets[activeTab]" 
                                    />

                                <!-- </b-col>

                            </b-row> -->
                        </template>
                        
                        <template v-else>
                            <!-- Список тікетів -->
                            <allTickets 
                                @addTicketList="addTicketList"
                            />
                        </template>
                        
                    </div>
                </div>

            </div>
            
  
            
        </template>
        
    </modal>

</template>
  
<script>
import modal from "@/components/modal/new/modal-fullscreen";
import formLeft from "./formLeft/index.vue"
import allTickets from './formLeft/listTicket.vue';
  
export default {
    props: ["obj", "ifPrevopen", "ifNextopen", "activeTab", "closeOne", "messages"],
    components: {
        modal,
        formLeft,
        allTickets
    },
    data() {
        return {
            form: {},
            activeTab: 0,
            tickets: [
            ],
            componentKey: 0,
        };
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj;
            this.tickets.push({ ...this.form }); // Клон об'єкта форми
        },
        addTicketList(ticketForm){
            console.log("addTicketList", ticketForm);
            this.tickets.push(ticketForm); // Додаємо новий тікет
        },
        setActiveTab(index) {
            this.activeTab = index;

            if (index === this.tickets.length) {
                console.log("Create new ticket tab selected");
            } else {
                this.updateFormWithActiveTab();
            }
        },
        updateFormWithActiveTab() {
            // Оновлення вмісту форми залежно від активного таба
            if (this.tickets[this.activeTab]) {
                this.form = { ...this.tickets[this.activeTab] };
            }
        },
    },
    computed: {
        ticketsWithAddTab() {
            return [...this.tickets, { ticketId: null }]; // Додаємо третій таб "+ Add"
        },
    },
  };
</script>
  
<style scoped>

.nav-link {
    position: relative;
    border: none;
    border-radius: 0;
}

.nav-tabs .tab-lines {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ddd; 
    z-index: 0;
}
  
.nav-link.active {
    font-weight: bold;
    background-color: transparent;
    z-index: 1;
}
  
.nav-link.active::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #000; 
    z-index: 1;
}
  
.tabsText {
    font-size: 1.2rem;
    /* font-weight: bold; */
}
.add-tab {
  color: #6c757d87; 
}
.left-block {
    height: 100%;
    background: #f4f4f5;
    width: 300px;
    position: static;
}
</style>
  